import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  IconButton,
  Stack,
  Table,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import DashboardLoader from "../../../components/Loaders/DashboardLoader";
import ExportDateRangeModal from "../../../components/ExportDateRangeModal";
import dayjs from "dayjs";

// icons
import DashboardCard from "../../../components/DashboardCard";
import MarketLocations from "../components/MarketLocations";
import URMapkitProvider from "../components/URMapkitProvider";
import useCSVDownloader from "../../../helpers/hooks/useCsvDownloader";
import { purple } from "@mui/material/colors";
import { urStorePalette } from "../../../themes/urStoreTheme";
import MetricsTable from "../components/Metrics/MetricsTable";
import MetricsRow from "../components/Metrics/MetricsRow";
import DashboardFilter from "../components/DashboardFilter";
import getExportCaptureDataByDate from "../../../api/dashboard/getExportCaptureDataByDate";
import { FileUploadOutlined, OpenInNewRounded } from "@mui/icons-material";
import { useMasterFilterStore } from "../../../stores/metrics/MasterFilterStore";
import getAllMetricsFilterOptions from "../../../api/dashboard/getAllMetricsFilterOptions";
import { useMetricsFilterOptions } from "../../../stores/metrics/MetricsOptionsStore";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";
import { useSnackbar } from "notistack";
import { MetricStack } from "../components/MetricStack";
import {
  GetPosComplianceTotals,
  GetRangedProductTotals,
  GetShareOfShelfTotals,
  SortByPositionCompliance,
  SortByStockCount,
} from "../components/CoreMetrics/util";
import { ProductMetrics } from "../../../models/metrics/ProductMetric";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DashboardFilterRequestParams } from "../helpers/DashboardHelpers";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import CoreMetricCard from "../components/CoreMetrics/CoreMetricCard";
import getOOSMetrics from "../../../api/dashboard/getOOSMetrics";
import getSOSMetrics from "../../../api/dashboard/getSOSMetrics";
import getPOSMetrics from "../../../api/dashboard/getPOSMetrics";
import { StoreMetric } from "../../../models/metrics/StoreMetric";

type DashboardIndexProps = {
  selectedTeam: string | null;
};

function DashboardIndex(props: DashboardIndexProps) {
  // Utilities
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { setStartDate, setEndDate, startDate, endDate } = useCSVDownloader(
    dayjs().subtract(1, "year").toDate(),
    dayjs().toDate()
  );

  // Stores
  const { 
    syncedFilters, 
    setSyncFilters, 
    syncedMetricData, 
    syncing,
    totalRangedProducts,
    totalExpectedProducts 
  } = useMasterFilterStore();
  const { setMetricsFilterOptionsData } = useMetricsFilterOptions();
  const { teams, setSelectedTeam, selectedTeam } = useTeamStore();

  // Constants
  const teamID = props.selectedTeam || null;

  // State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [exportProcessing, setExportProcessing] = useState<boolean>(false);
  const [isExportModalOpen, setisExportModalOpen] = useState<boolean>(false);
  const [sortDescending, setSortDescending] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [expandedCardKey, setExpandedCardKey] = useState<
    "oos" | "oop" | "sos" | null
  >(null);

  // Refs, used for tracking expanded views.
  const oosRef = useRef(null);
  const sosRef = useRef(null);
  const oopRef = useRef(null);

  // Functions
  const scrollToRef = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  // This function is responsible for calculating the sum of the metrics of
  // the store data of a team. A team can contain multiple stores, and each
  // store has its own metrics. We need to sum the metrics of all the stores
  // to get the total metrics of the team. Ex. sumOfMetricsKey("RangingStockCount")
  // gets the sum of the RangingStockCount values of all the stores in the team.
  const sumOfMetricsKey = (key: keyof StoreMetric): number => {
    if (!syncedMetricData || syncedMetricData.length === 0) return 0;
    return syncedMetricData.reduce((sum, obj) => sum + Number(obj[key]), 0);
  };

  const rangingValue = totalExpectedProducts > 0 
    ? totalRangedProducts / totalExpectedProducts 
    : 0;

  const shareOfShelfValue =
    sumOfMetricsKey("StockTotalArea") / sumOfMetricsKey("TotalArea");

  const positionComplianceValue =
    sumOfMetricsKey("PositionComplianceSumMatch") /
    sumOfMetricsKey("PositionComplianceTotalSum");

  const calculateComplianceScore = () => {
    // Ranging (70% weight)
    const rangingScore = totalExpectedProducts > 0 
      ? totalRangedProducts / totalExpectedProducts 
      : 0;

    // Position Compliance (10% weight)
    const positionComplianceScore =
      sumOfMetricsKey("PositionComplianceSumMatch") /
      sumOfMetricsKey("PositionComplianceTotalSum");

    // Share of Shelf (20% weight)
    const stockTotalAreaScore =
      sumOfMetricsKey("StockTotalArea") / sumOfMetricsKey("TotalArea");

    // Calculate weighted average of metrics.
    const weightedAverage =
      0.7 * rangingScore +
      0.1 * positionComplianceScore +
      0.2 * stockTotalAreaScore;

    return weightedAverage;
  };

  const getIndividualStoreComplianceScore = (store: StoreMetric) => {
    return (
      ((store.StoreTotalStockCount +
        store.PositionComplianceSumMatch +
        store.StockTotalArea) /
        (store.StoreTotalProduct +
          store.PositionComplianceTotalSum +
          store.TotalArea)) *
      100
    );
  };

  const sortedStores = syncedMetricData.slice().sort((a, b) => {
    const metricA = getIndividualStoreComplianceScore(a);
    const metricB = getIndividualStoreComplianceScore(b);

    return sortDescending ? metricB - metricA : metricA - metricB;
  });

  const toggleSortOrder = () => {
    setSortDescending(!sortDescending);
  };

  useEffect(() => {
    // DEFINE KEYS THAT NEED TO PASS THEIR VALUES AS ARRAYS.
    // THIS WILL NEED TO BE UPDATED IF MORE FILTERS SUPPORT MULTIPLE VALUES.
    const MULTI_VALUE_KEYS = ["storeId", "state", "category", "subcategory", "manufacturer", "brand", "bayCount", "fixtureType"];

    // This first-load effect will check to see if we should auto-apply filters from the URL.
    const filters = Array.from(searchParams.entries());

    // The first entry will always be teamId, check to see if we can auto-swap the user's team.
    const teamFilter = filters.find(([key, value]) => key === "teamId");

    if (teamFilter && teams.map((t) => t.TeamId).includes(teamFilter[1])) {
      // The teamId in the filters is in our list of permitted teams, and we aren't already on that team, swap and notify.
      if (teamFilter[1] !== selectedTeam) {
        setSelectedTeam(teamFilter[1]);
        enqueueSnackbar("Team Swapped", {
          variant: "success",
          cta: `Team was changed to ${
            teams.find((t) => t.TeamId === teamFilter[1])?.Name
          } automatically.`,
          preventDuplicate: true,
          autoHideDuration: 5000,
        });
      }

      // Group filters by key. Basically, [{key, [values]}].
      const groupedFilters: { [key: string]: string[] } = filters.reduce(
        (acc, [key, value]) => {
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(value);
          return acc;
        },
        {} as { [key: string]: string[] }
      );

      // Apply filters detected in the URL. Values are applied in bulk per key.
      (
        Object.entries(groupedFilters) as [
          keyof DashboardFilterRequestParams,
          string[]
        ][]
      ).forEach(([key, values]) => {
        const substitutedValues = values.map((value) =>
          decodeURIComponent(value).replaceAll("^", "&")
        );

        setSyncFilters(
          [key],
          MULTI_VALUE_KEYS.includes(key)
            ? substitutedValues
            : substitutedValues[0]
        );
      });
    } else {
      // If there was a team filter but we couldn't access it, show an alert. Otherwise, skip.
      if (searchParams.has("teamId")) {
        // The user does not have access to the team in the filter. Do not proceed with filtering.
        enqueueSnackbar("Inaccessible Team", {
          variant: "warning",
          cta: "The link included a team you do not have access to, and cannot be loaded.",
          preventDuplicate: true,
        });
      }
    }
  }, []);

  useEffect(() => {
    // This effect manages the current route. This props up the 'share' feature of the dashboard.
    let newSearchParams = new URLSearchParams();

    Object.entries(syncedFilters!).forEach(([key, value]) => {
      if (value !== null) {
        // First, replace '&' characters with '^', because they mess up reads of the params when decoding.
        if (Array.isArray(value)) {
          // Cleanout first
          newSearchParams.delete(key);

          // Set 1 new param for each array value.
          value.forEach((v) => newSearchParams.append(key, v));
        } else {
          const substitutedValue = value.replaceAll("&", "^");
          newSearchParams.set(key, substitutedValue);
        }
      }
    });

    // Update the search params in the URL
    navigate(`${location.pathname}?${newSearchParams.toString()}`, {
      replace: true,
    });
  }, [syncedFilters]);

  useEffect(() => {
    const fetchAllMetrics = async () => {
      if (teamID == null) {
        return;
      }
      try {
        if (syncedFilters != null) {
          setSyncFilters(["teamId"], teamID);
        }

        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        console.log(error.message);
      }
    };

    const fetchAllMetricsFilterOptions = async () => {
      if (teamID == null) {
        return;
      }
      try {
        const data = await getAllMetricsFilterOptions(teamID);
        if (data) {
          setMetricsFilterOptionsData(data);
        }
      } catch (error: any) {
        console.log(error.message);
      }
    };

    fetchAllMetrics();
    fetchAllMetricsFilterOptions();
  }, [teamID]);

  async function handleExport() {
    if (!startDate || !endDate) return;

    const formattedStartDate = dayjs(startDate).toISOString();
    const formattedEndDate = dayjs(endDate).endOf("day").toISOString();
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      setExportProcessing(true);
      const data = await getExportCaptureDataByDate(
        formattedStartDate,
        formattedEndDate,
        userTimezone,
        teamID!,
        syncedFilters
      );
      if (data) {
        enqueueSnackbar("Export has been added to your downloads.", {
          variant: "success",
        });
        setisExportModalOpen(false);
      }
    } catch (error) {
      console.error("Error fetching CSV data:", error);
      enqueueSnackbar(
        "Export failed. Please try again later, or contact support.",
        {
          variant: "error",
        }
      );
    } finally {
      setExportProcessing(false);
    }
  }

  function renderExportControl() {
    return (
      <Button
        sx={{ ml: 2 }}
        variant="contained"
        startIcon={<FileUploadOutlined />}
        onClick={() => setisExportModalOpen(true)}
      >
        Store Metrics (.csv)
      </Button>
    );
  }

  function handleStoreClick(storeId: string) {
    const swapStore = sortedStores.find((s) => s.StoreId === storeId);

    if (swapStore) {
      // Autopopulate relevant filters.
      setSyncFilters(["country"], swapStore.Country);
      if (swapStore.Country === "Australia") {
        setSyncFilters(["state"], [swapStore.State!]);
      } else {
        setSyncFilters(["state"], null);
      }
      setSyncFilters(["storeId"], [swapStore.StoreId]);
    } else {
      // Dev err (shouldnt hit, but if it does, ticket time!)
      console.error(
        "Filters were set in an unsafe way and may be invalid/corrupt now."
      );
    }
  }

  return isLoading ? (
    <DashboardLoader />
  ) : (
    <Box
      sx={ResponsiveContainerStyle}
      display="flex"
      flexDirection="column"
      alignContent="stretch"
    >
      <Card sx={{ p: 2, mb: 4 }}>
        <DashboardFilter teamId={teamID!} plugins={renderExportControl()} />
      </Card>

      <Grid
        container
        spacing={4}
        sx={{ maxHeight: "calc(100vh - 148px)", overflow: "auto" }}
      >
        <Grid item container xs={12} sm={12} md={12} lg={6} spacing={4}>
          <Grid item xs={12}>
            {/* COMPLIANCE SCORE */}
            <DashboardCard title="Total Compliance">
              <MetricStack
                value={(calculateComplianceScore() * 100).toFixed(0)}
                color={purple["A100"]}
                helperText={`Across ${syncedMetricData.length} store${
                  syncedMetricData.length > 1 ? "s" : ""
                }.`}
                isLoading={syncing}
                noData={!syncedMetricData || syncedMetricData.length === 0}
              />
            </DashboardCard>
          </Grid>

          <Grid item xs={12}>
            {/* COMPLIANCE BY STORE */}
            <DashboardCard sxStyle={{ height: "215px" }}>
              <Box>
                <MetricsTable
                  title="Store Compliance"
                  actions={
                    <Stack sx={{ mb: 2, mt: 4 }} display="flex" direction="row">
                      <ButtonGroup
                        sx={{ height: "20px", mt: "-14px" }}
                        size="small"
                      >
                        <Button
                          variant={sortDescending ? "contained" : "outlined"}
                          onClick={() => toggleSortOrder()}
                        >
                          Top
                        </Button>
                        <Button
                          variant={sortDescending ? "outlined" : "contained"}
                          onClick={() => toggleSortOrder()}
                        >
                          Bottom
                        </Button>
                      </ButtonGroup>
                    </Stack>
                  }
                >
                  <div
                    style={{
                      minHeight: "150px",
                      overflow: "auto",
                      maxHeight: "159px",
                    }}
                  >
                    <Table
                      size="small"
                      sx={{ tableLayout: "auto", width: "100%" }}
                    >
                      {sortedStores?.map((store: StoreMetric) => (
                        <MetricsRow
                          onRowClick={() => handleStoreClick(store.StoreId)}
                          key={store.Name}
                          primaryText={store.Name}
                          secondaryText={`${getIndividualStoreComplianceScore(
                            store
                          ).toFixed(0)}%`}
                          secondaryTextColor={urStorePalette.greys.darkest}
                          displayIcon={false}
                        />
                      ))}
                    </Table>
                  </div>
                </MetricsTable>
              </Box>
            </DashboardCard>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          {/* STORE LOCATIONS */}
          <DashboardCard
            sxStyle={{ height: "100%", minHeight: "300px", width: "100%" }}
            contentStyle={{
              p: 0,
              "&:last-of-type": { pb: 0 },
            }}
          >
            <URMapkitProvider>
              <MarketLocations />
            </URMapkitProvider>
          </DashboardCard>
        </Grid>
        <Grid item xs={12} lg={4} md={4}>
          <DashboardCard title={"Ranging"}>
            <MetricStack
              value={(rangingValue * 100).toFixed(0)}
              color={urStorePalette.metric.blue}
              helperText={`${totalRangedProducts?.toLocaleString()} / ${totalExpectedProducts?.toLocaleString()} products ranged.`}
              isLoading={syncing}
              noData={!syncedMetricData || syncedMetricData.length === 0}
            />
          </DashboardCard>
        </Grid>
        <Grid item xs={12} lg={4} md={4}>
          <DashboardCard title={"Share of Shelf"}>
            <MetricStack
              value={(shareOfShelfValue * 100).toFixed(0)}
              color={urStorePalette.metric.orange}
              helperText={`${GetShareOfShelfTotals(
                syncedMetricData
              ).shareCurrent.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}cm² / ${GetShareOfShelfTotals(
                syncedMetricData
              ).shareTotal.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}cm² shelf coverage.`}
              isLoading={syncing}
              noData={!syncedMetricData || syncedMetricData.length === 0}
            />
          </DashboardCard>
        </Grid>
        <Grid item xs={12} lg={4} md={4}>
          <DashboardCard title={"Position Compliance"}>
            <MetricStack
              value={(positionComplianceValue * 100).toFixed(0)}
              color={urStorePalette.metric.green}
              helperText={`${GetPosComplianceTotals(
                syncedMetricData
              ).positionCorrectCurrent.toLocaleString()} / ${GetPosComplianceTotals(
                syncedMetricData
              ).positionCorrectTotal.toLocaleString()} correct positions.`}
              isLoading={syncing}
              noData={!syncedMetricData || syncedMetricData.length === 0}
            />
          </DashboardCard>
        </Grid>
        {/* Drill Metrics */}
        <Grid
          item
          xs={12}
          md={expandedCardKey === "oos" ? 12 : 6}
          lg={expandedCardKey === "oos" ? 12 : 4}
          sx={{
            transition: "all 0.3s",
            display:
              expandedCardKey && expandedCardKey !== "oos" ? "none" : "block",
          }}
          ref={oosRef}
        >
          <CoreMetricCard
            title="Out of Stock Products"
            plugins={
              <IconButton
                size="small"
                onClick={() => {
                  function ManageScroll() {
                    setExpandedCardKey("oos");
                    // Timeout on the autoscrol.
                    setTimeout(() => {
                      scrollToRef(oosRef);
                    }, 350);
                  }

                  expandedCardKey && expandedCardKey === "oos"
                    ? setExpandedCardKey(null)
                    : ManageScroll();
                }}
              >
                <OpenInNewRounded fontSize={"small"} />
              </IconButton>
            }
            color={urStorePalette.metric.blue}
            aggregateSortFunction={SortByStockCount}
            sortOrder={"asc"}
            completeKey="StockCount"
            totalKey="TotalCount"
            expanded={expandedCardKey === "oos"}
            getAggregateEntities={getOOSMetrics}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={expandedCardKey === "sos" ? 12 : 6}
          lg={expandedCardKey === "sos" ? 12 : 4}
          sx={{
            transition: "all 0.3s",
            display:
              expandedCardKey && expandedCardKey !== "sos" ? "none" : "block",
          }}
          ref={sosRef}
        >
          <CoreMetricCard
            title="Manufacturer Share of Shelf"
            plugins={
              <IconButton
                size="small"
                onClick={() => {
                  function ManageScroll() {
                    setExpandedCardKey("sos");
                    // Timeout on the autoscrol.
                    setTimeout(() => {
                      scrollToRef(sosRef);
                    }, 350);
                  }

                  expandedCardKey && expandedCardKey === "sos"
                    ? setExpandedCardKey(null)
                    : ManageScroll();
                }}
              >
                <OpenInNewRounded fontSize={"small"} />
              </IconButton>
            }
            color={urStorePalette.metric.orange}
            completeKey="InStockShareOfShelfArea"
            totalKey="ShareOfShelfTotalArea"
            expanded={expandedCardKey === "sos"}
            getAggregateEntities={getSOSMetrics}
            unitSuffix="cm²"
            fixedDecimalPoints={0}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={expandedCardKey === "oop" ? 12 : 6}
          lg={expandedCardKey === "oop" ? 12 : 4}
          sx={{
            transition: "all 0.3s",
            display:
              expandedCardKey && expandedCardKey !== "oop" ? "none" : "block",
          }}
        >
          <CoreMetricCard
            title="Position Non-Compliance"
            plugins={
              <IconButton
                size="small"
                onClick={() => {
                  function ManageScroll() {
                    setExpandedCardKey("oop");
                    // Timeout on the autoscrol.
                    setTimeout(() => {
                      scrollToRef(oopRef);
                    }, 350);
                  }

                  expandedCardKey && expandedCardKey === "oop"
                    ? setExpandedCardKey(null)
                    : ManageScroll();
                }}
              >
                <OpenInNewRounded fontSize={"small"} />
              </IconButton>
            }
            aggregateSortFunction={SortByPositionCompliance}
            color={urStorePalette.metric.green}
            completeKey="InStockPositions"
            totalKey="TotalPositions"
            expanded={expandedCardKey === "oop"}
            getAggregateEntities={getPOSMetrics}
          />
        </Grid>
      </Grid>
      <ExportDateRangeModal
        setStartDate={(date: Date | null) => setStartDate(date)}
        setEndDate={(date: Date | null) => setEndDate(date)}
        startDate={startDate ? dayjs(startDate) : null}
        endDate={endDate ? dayjs(endDate) : null}
        open={isExportModalOpen}
        handleClose={() => setisExportModalOpen(false)}
        handleExport={handleExport}
        exportIsProcessing={exportProcessing}
        info="Dashboard filters will apply to this export."
      />
    </Box>
  );
}

export default DashboardIndex;
