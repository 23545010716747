import {
  Box,
  ButtonGroup,
  TextField,
  Popover,
  Button,
  Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { AgGridWrapper } from "../../../../components/AgGridWrapper/AgGridWrapper";
import { useEffect, useState } from "react";
import { ColDef } from "ag-grid-community";
import { LoadingButton } from "@mui/lab";
import { AddCircleOutline, Edit } from "@mui/icons-material";
import { formatDisplayDate } from "../../../../components/AgGridWrapper/utilities";
import { ResponsiveContainerStyle } from "../../../../helpers/generalUtilities";
import {
  getProductClasses,
  ProductClass,
} from "../../../../api/productClasses/getProductClasses";
import upsertProductClass from "../../../../api/productClasses/upsertProductClass";

export const ListProductClasses = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [colDefs] = useState<ColDef<ProductClass>[]>([
    { field: "Name" },
    {
      field: "CreatedAt",
      cellRenderer: (_data: any) => formatDisplayDate(_data.value),
    },
    {
      field: "LastModified",
      headerName: "Actions",
      cellRenderer: (params: any) => (
        <Button
          variant="contained"
          size="small"
          startIcon={<Edit />}
          onClick={(event) => handleEditClick(event, params.data)}
        >
          Edit
        </Button>
      ),
    },
  ]);

  const [productClasses, setProductClasses] = useState<ProductClass[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [newClassName, setNewClassName] = useState<string>("");
  const [newClassLoading, setNewClassLoading] = useState<boolean>(false);
  const [editingClass, setEditingClass] = useState<ProductClass | null>(null);
  const [editAnchorEl, setEditAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const [editedName, setEditedName] = useState<string>("");

  useEffect(() => {
    fetchProductClasses();
  }, []);

  const fetchProductClasses = async () => {
    try {
      setLoading(true);
      const data = await getProductClasses();
      setProductClasses(data);
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to load Product Classes.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpsertProductClass = async () => {
    try {
      setNewClassLoading(true);
      await upsertProductClass({ Name: newClassName });
      enqueueSnackbar("Success", {
        variant: "success",
        cta: `'${newClassName}' was created.`,
      });
      setNewClassName("");
      await fetchProductClasses();
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to create Product Class.",
      });
    } finally {
      setNewClassLoading(false);
    }
  };

  const handleEditClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    productClass: ProductClass
  ) => {
    setEditingClass(productClass);
    setEditedName(productClass.Name);
    setEditAnchorEl(event.currentTarget);
  };

  const handleEditClose = () => {
    setEditingClass(null);
    setEditAnchorEl(null);
  };

  const handleEditSave = async () => {
    if (editingClass) {
      try {
        await upsertProductClass({
          ProductClassId: editingClass.ProductClassId,
          Name: editedName,
        });
        enqueueSnackbar("Success", {
          variant: "success",
          cta: `Product Class name updated to '${editedName}'.`,
        });
        await fetchProductClasses();
        handleEditClose();
      } catch {
        enqueueSnackbar("Error", {
          variant: "error",
          cta: "Failed to update Product Class name.",
        });
      }
    }
  };

  return (
    <Box sx={ResponsiveContainerStyle}>
      <AgGridWrapper
        id="product-classes"
        rowData={productClasses}
        columnDefs={colDefs}
        loading={loading}
        quickSearch
        export
        rememberPageNumber
        plugins={
          <ButtonGroup>
            <TextField
              value={newClassName}
              onChange={(e) => setNewClassName(e.target.value)}
              InputProps={{
                style: {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: "6px",
                },
              }}
              label="Product Class Name"
              size="small"
            />
            <LoadingButton
              loading={newClassLoading}
              onClick={handleUpsertProductClass}
              sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              variant="contained"
              disabled={newClassName === ""}
              startIcon={<AddCircleOutline />}
            >
              Add Product Class
            </LoadingButton>
          </ButtonGroup>
        }
      />
      <Popover
        open={Boolean(editAnchorEl)}
        anchorEl={editAnchorEl}
        onClose={handleEditClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack direction={"column"} spacing={2} p={4}>
          <TextField
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            label="Edit Product Class Name"
            size="small"
            sx={{ mb: 2 }}
          />
          <LoadingButton
            onClick={handleEditSave}
            variant="contained"
            disabled={editedName === "" || editedName === editingClass?.Name}
          >
            Save
          </LoadingButton>
        </Stack>
      </Popover>
    </Box>
  );
}; 