import dayjs from "dayjs";
import { DashboardFilterRequestParams } from "../../features/dashboard/helpers/DashboardHelpers";
import axiosInstance from "../axiosInstanceConfig";
import { StoreMetric } from "../../models/metrics/StoreMetric";
import { generateMetricParams } from "../../helpers/generalUtilities";

export interface GetMyMetricsResponse {
  StoreMetrics: StoreMetric[];
  RangingTotalStockCount: number;
  RangingTotalProduct: number;
}

const getMyMetrics = async (
  _params: DashboardFilterRequestParams,
  options?: { signal?: AbortSignal }
) => {
  let urlQueryParameters = generateMetricParams(_params);

  _params.startDate &&
    urlQueryParameters.append(
      "startDate",
      dayjs(_params.startDate, "DD-MM-YYYY").toISOString()
    );
  _params.endDate &&
    urlQueryParameters.append(
      "endDate",
      dayjs(_params.endDate, "DD-MM-YYYY").endOf("day").toISOString()
    );

  const response = await axiosInstance.get<GetMyMetricsResponse>(
    `store/MyMetrics?${urlQueryParameters.toString()}`,
    options
  );

  return response.data;
};

export default getMyMetrics;
