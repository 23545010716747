import axiosInstance from "../axiosInstanceConfig";
import { TaskForm } from "../../features/tasks/components/TaskDialog";
import { TaskType } from "./getTaskTypes";
import dayjs from "dayjs";

const postUpsertTask = async (
  _taskForm: TaskForm,
  _selectedTeam: string,
  _selectedTaskType: TaskType
) => {
  // Massage the start and end dates to be forced to midnight.
  const startDate = dayjs(_taskForm.startDate).startOf("day").toDate();
  const endDate = dayjs(_taskForm.endDate).startOf("day").toDate();

  // Send request
  const response = await axiosInstance.post(`task/CreateUpdateTask`, {
    // Required Fields
    TaskId: _taskForm.taskId === "" ? null : _taskForm.taskId,
    Name: _taskForm.name,
    TaskTypeId: _taskForm.taskType,
    StartDate: startDate,
    EndDate: endDate,
    FirstCaptureInstruction: !_selectedTaskType.HasFirstCaptureInstructions
      ? null
      : _taskForm.firstCaptureInstruction,
    MerchandiseInstruction: !_selectedTaskType.HasMerchandiseInstructions
      ? null
      : _taskForm.merchandiseInstruction,
    SecondCaptureInstruction: !_selectedTaskType.HasSecondCaptureInstructions
      ? null
      : _taskForm.secondCaptureInstruction,
    PlanogramRevisionId: _taskForm.planogram,
    TeamId: _selectedTeam,

    // Optional Fields
    ...(_taskForm.jobId ? { JobId: _taskForm.jobId } : {}),
  });

  return response.data;
};

export default postUpsertTask;
