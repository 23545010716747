import axiosInstance from "../axiosInstanceConfig";

interface UpsertProductClassRequest {
  ProductClassId?: string;
  Name: string;
}

const upsertProductClass = async (
  request: UpsertProductClassRequest
): Promise<string> => {
  const response = await axiosInstance.post(
    "/productclass/AdminCreateUpdateProductClass",
    request
  );
  return response.data;
};

export default upsertProductClass; 