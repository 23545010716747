import {
  HomeRounded,
  StoreRounded,
  GridViewRounded,
  ShoppingCartRounded,
  PeopleRounded,
  ConstructionRounded,
  SellRounded,
  FactCheckRounded,
  Assignment,
  WidgetsRounded,
  PaddingRounded,
  DataArrayRounded,
  AdminPanelSettingsRounded,
} from "@mui/icons-material";
import { NavItem } from "../../../stores/layout/LayoutStoreTypes";

export const navItems: NavItem[] = [
  {
    label: "Dashboard",
    path: "/dashboard",
    icon: <HomeRounded />,
    iconTag: "dashboard",
    admin: false,
    showInSidebar: true,
    reporting: true,
  },
  {
    label: "Stores",
    path: "/stores",
    icon: <StoreRounded />,
    iconTag: "stores",
    admin: false,
    showInSidebar: true,
    reporting: true,
  },
  {
    label: "Snapshots",
    path: "/planograms",
    icon: <GridViewRounded />,
    iconTag: "planograms",
    admin: false,
    showInSidebar: true,
    reporting: true,
  },
  {
    label: "Products",
    path: "/products",
    icon: <ShoppingCartRounded />,
    iconTag: "products",
    admin: false,
    showInSidebar: true,
    reporting: true,
  },
  {
    label: "My Team",
    path: "/team",
    icon: <PeopleRounded />,
    iconTag: "team",
    admin: false,
    showInSidebar: true,
    reporting: false,
  },
  {
    label: "My Teams",
    path: "/my-teams",
    icon: <PeopleRounded />,
    iconTag: "team",
    admin: false,
    showInSidebar: false,
    reporting: false,
  },
  {
    label: "Jobs",
    path: "/jobs",
    iconTag: "task",
    icon: <FactCheckRounded />,
    admin: false,
    showInSidebar: true,
    reporting: false,
  },
];

export const adminNavItems: NavItem[] = [
  {
    label: "Manage",
    icon: <AdminPanelSettingsRounded />,
    iconTag: "categories", // unused for parent items. will update in future pr.
    admin: true,
    showInSidebar: true,
    reporting: false,
    children: [
      {
        label: "Planograms",
        path: "/admin/planograms",
        icon: <GridViewRounded />,
        iconTag: "planograms",
        admin: true,
        showInSidebar: true,
        reporting: false,
      },
      {
        label: "Products",
        path: "/admin/products",
        icon: <ShoppingCartRounded />,
        iconTag: "products",
        admin: true,
        showInSidebar: true,
        reporting: false,
      },
      {
        label: "Stores",
        path: "/admin/stores",
        icon: <StoreRounded />,
        iconTag: "stores",
        admin: true,
        showInSidebar: true,
        reporting: false,
      },
      {
        label: "Users",
        path: "/admin/users",
        icon: <PeopleRounded />,
        iconTag: "team",
        admin: true,
        showInSidebar: true,
        reporting: false,
      },
    ],
  },

  {
    label: "Data",
    icon: <AdminPanelSettingsRounded />,
    iconTag: "categories", // unused for parent items. will update in future pr.
    admin: true,
    showInSidebar: true,
    reporting: false,
    children: [
      {
        label: "Planogram Categories",
        path: "/admin/categories",
        icon: <SellRounded />,
        iconTag: "categories",
        admin: true,
        showInSidebar: true,
        reporting: false,
      },
      {
        label: "Fixture Types",
        path: "/admin/fixtures",
        icon: <WidgetsRounded />,
        iconTag: "fixtures",
        admin: true,
        showInSidebar: true,
        reporting: false,
      },
      {
        label: "Layout IDs",  
        path: "/admin/layouts",
        icon: <PaddingRounded />,
        iconTag: "layouts",
        reporting: false,
        admin: true,
        showInSidebar: true,
      },
      {
        label: "Product Classes",
        path: "/admin/product-classes",
        icon: <DataArrayRounded />,
        iconTag: "layouts",
        reporting: false,
        admin: true,
        // This is disabled for now, not designed for client usage yet.
        showInSidebar: false,
      },
    ],
  },
  {
    label: "Utilities",
    icon: <AdminPanelSettingsRounded />,
    iconTag: "categories", // unused for parent items. will update in future pr.
    admin: true,
    showInSidebar: true,
    reporting: false,
    children: [
      {
        label: "ML Exports",
        path: "/admin/exports",
        icon: <ConstructionRounded />,
        iconTag: "exports",
        admin: true,
        showInSidebar: true,
        reporting: false,
      },
      {
        label: "Assignments",
        path: "/admin/assignments",
        icon: <Assignment />,
        iconTag: "assignments",
        admin: true,
        showInSidebar: true,
        reporting: false,
      },
    ]
  },

  
];
