import axiosInstance from "../axiosInstanceConfig";

export interface ProductClass {
  ProductClassId: string;
  Name: string;
  TrainingImages: null;
  CreatedAt: string;
  LastModified: string;
}

export const getProductClasses = async (): Promise<ProductClass[]> => {
  const response = await axiosInstance.get("/productclass/AdminProductClassList");
  return response.data;
}; 