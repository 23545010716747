import { MenuItem, TextField, CircularProgress } from "@mui/material";
import { useState } from "react";
import { urStorePalette } from "../../../../themes/urStoreTheme";
import { ProductClass } from "../../../../api/productClasses/getProductClasses";

interface ProductClassSelectorProps {
  initialValue: string;
  productClasses: ProductClass[];
  onClassChange: (newClass: string) => Promise<void>;
}

const ProductClassSelector = ({
  initialValue,
  productClasses,
  onClassChange,
}: ProductClassSelectorProps) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(initialValue);

  const handleChange = async (newValue: string) => {
    try {
      setLoading(true);
      setValue(newValue);
      await onClassChange(newValue);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TextField
      select
      label="Product Class"
      sx={{
        backgroundColor: urStorePalette.greys.lightest,
      }}
      size="small"
      fullWidth
      value={value || ""}
      onChange={(e) => handleChange(e.target.value)}
      disabled={loading}
      InputProps={{
        endAdornment: loading && (
          <CircularProgress
            color="inherit"
            size={"20px"}
            sx={{ position: "absolute", right: 9 }}
          />
        ),
      }}
    >
      {productClasses.map((pc) => (
        <MenuItem key={pc.ProductClassId} value={pc.ProductClassId}>
          {pc.Name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ProductClassSelector; 