import { create } from "zustand";
import { DashboardFilterRequestParams } from "../../features/dashboard/helpers/DashboardHelpers";
import getAllMetrics from "../../api/dashboard/getAllMetrics";
import getMyMetrics from "../../api/dashboard/getMyMetrics";
import { enqueueSnackbar } from "notistack";
import { CanceledError } from "axios";
import { ProductMetrics } from "../../models/metrics/ProductMetric";
import { StoreMetric } from "../../models/metrics/StoreMetric";

type MasterFilterStore = {
  // Per Store Metrics
  syncedMetricData: StoreMetric[];

  // Top level ranging totals
  totalRangedProducts: number;
  totalExpectedProducts: number;

  // Filters currently applied to the metrics.
  syncedFilters: DashboardFilterRequestParams | null;

  // If the main metric request is loading currently.
  syncing: boolean;

  // Abort controller to handle interrupted requests.
  abortController: AbortController | null;
};

export interface OOSAggregate
  extends Omit<
    ProductMetrics,
    | "InStockShareOfShelfArea"
    | "ShareOfShelfTotalArea"
    | "InStockPositions"
    | "TotalPositions"
    | "Manufacturer"
    | "InStock"
  > {
  InStock: boolean;
  Percentage: number;
  StockCount: number;
  TotalProduct: number;
}

export interface OOPAggregate
  extends Omit<
    ProductMetrics,
    | "InStockShareOfShelfArea"
    | "ShareOfShelfTotalArea"
    | "InStockPositions"
    | "TotalPositions"
    | "Manufacturer"
    | "InStock"
  > {
  Percentage: number;
  SumMatch: number;
  TotalSum: number;
}

export interface SOSAggregate
  extends Omit<
    ProductMetrics,
    | "InStockShareOfShelfArea"
    | "ShareOfShelfTotalArea"
    | "InStockPositions"
    | "TotalPositions"
    | "InStock"
  > {
  Percentage: number;
  StockTotalArea: number;
  TotalArea: number;
}

type MasterFilterActions = {
  setMetricData: (
    _params: DashboardFilterRequestParams,
    _requestMode: "all" | "restricted"
  ) => void;

  setSyncFilters: (
    _fields: (keyof DashboardFilterRequestParams)[],
    _newValue: string | string[] | null
  ) => void;
};

function buildEmptyParams() {
  const emptyReqParams: DashboardFilterRequestParams = {
    teamId: "",
    bayCount: null,
    brand: null,
    category: null,
    country: null,
    manufacturer: null,
    state: null,
    subcategory: null,
    upc: null,
    storeId: null,
    startDate: null,
    endDate: null,
    fixtureType: null,
  };

  return emptyReqParams;
}

/**
 * Store containing functionality for managing combined filter state
 * and synchronising those filters with the API.
 * @field `syncedMetricData` Per-Store MetricResponseData from server.
 * @field `syncedFilters` The combined filter state.
 * @field `syncing` Indicates whether or not an API request is processing currently on this hook.
 * @function `setMetricData` Fetches metric data and stores result to syncedMetricData.
 * @function `setSyncFilters` Sets the globally tracked SyncFilters.
 */
export const useMasterFilterStore = create<
  MasterFilterStore & MasterFilterActions
>((set) => ({
  syncedMetricData: [],
  totalRangedProducts: 0,
  totalExpectedProducts: 0,
  syncedFilters: buildEmptyParams(),
  syncing: false,
  abortController: null,

  setMetricData: async (_params, _requestMode) => {
    // Before re-requesting, cancel current requests to prevent desynced resolutions.
    if (useMasterFilterStore.getState().abortController) {
      console.log("request aborted");
      useMasterFilterStore.getState().abortController?.abort();
    }

    // If not aborting, create a new abortController.
    const abortController = new AbortController();
    set(() => ({ abortController }));

    try {
      set(() => ({ syncing: true }));

      if (_requestMode === "all") {
        // Request for team admins or greater.
        const data = await getAllMetrics(
          {
            teamId: _params.teamId,
            bayCount: _params.bayCount,
            brand: _params.brand,
            category: _params.category,
            country: _params.country,
            manufacturer: _params.manufacturer,
            state: _params.state,
            subcategory: _params.subcategory,
            upc: _params.upc,
            storeId: _params.storeId,
            startDate: _params.startDate,
            endDate: _params.endDate,
            fixtureType: _params.fixtureType,
          },
          { signal: abortController.signal }
        );

        set(() => ({
          syncedMetricData: data.StoreMetrics as StoreMetric[],
          totalRangedProducts: data.RangingTotalStockCount,
          totalExpectedProducts: data.RangingTotalProduct,
        }));
      } else {
        // Request for reporting users.
        const data = await getMyMetrics(
          {
            teamId: _params.teamId,
            bayCount: _params.bayCount,
            brand: _params.brand,
            category: _params.category,
            country: _params.country,
            manufacturer: _params.manufacturer,
            state: _params.state,
            subcategory: _params.subcategory,
            upc: _params.upc,
            storeId: _params.storeId,
            startDate: _params.startDate,
            endDate: _params.endDate,
            fixtureType: _params.fixtureType,
          },
          { signal: abortController.signal }
        );

        set(() => ({
          syncedMetricData: data.StoreMetrics as StoreMetric[],
          totalRangedProducts: data.RangingTotalStockCount,
          totalExpectedProducts: data.RangingTotalProduct,
        }));
      }
    } catch (e) {
      if (e instanceof CanceledError && e.code === "ERR_CANCELED") {
        // Do nothing if the error was caused by a cancellation.
      } else {
        // Otherwise, show an error snack.
        enqueueSnackbar("Failed to load Metrics", {
          variant: "error",
          preventDuplicate: true,
        });
      }
    } finally {
      // Turn loading off (unless request aborted, in which case let other req handle the turnoff).
      if (!abortController?.signal.aborted) {
        set(() => ({ syncing: false }));
      }
    }
  },

  setSyncFilters: (_fields, _newValue) =>
    set((state) => {
      if (state.syncedFilters) {
        const copyFilter: DashboardFilterRequestParams = {
          ...state.syncedFilters,
        };

        _fields.forEach((_f) => {
          //@ts-expect-error TS whining about unsafe indexing. Trust me, its safe.
          copyFilter[_f] = _newValue;
        });

        return { syncedFilters: copyFilter };
      }

      // Fallthrough.
      return state;
    }),
}));
