import { Box, CircularProgress } from "@mui/material";
import {
  OOSAggregate,
  OOPAggregate,
  SOSAggregate,
} from "../../../../stores/metrics/MasterFilterStore";
import { StoreMetric } from "../../../../models/metrics/StoreMetric";

// Shared styles and helpers between the drill metrics on the dashboard.
export const DrillMetricPaperStyle = {
  height: { sm: 280, md: 320, lg: 360 },
  overflow: "auto",
  mt: -4,
  pt: 1,
};

export const GetRangedProductTotals = (metrics: StoreMetric[]) => {
  let totals = { rangedCurrently: 0, rangedTotal: 0 };

  metrics.forEach((m) => {
    totals.rangedCurrently = totals.rangedCurrently + m.StoreTotalStockCount;
    totals.rangedTotal = totals.rangedTotal + m.StoreTotalProduct;
  });

  return totals;
};

export const GetShareOfShelfTotals = (metrics: StoreMetric[]) => {
  let totals = { shareCurrent: 0, shareTotal: 0 };

  metrics.forEach((m) => {
    totals.shareCurrent = (totals.shareCurrent + m.StockTotalArea);
    totals.shareTotal = (totals.shareTotal + m.TotalArea) ;
  });

  return totals;
};

export const GetPosComplianceTotals = (metrics: StoreMetric[]) => {
  let totals = { positionCorrectCurrent: 0, positionCorrectTotal: 0 };

  metrics.forEach((m) => {
    totals.positionCorrectCurrent =
      totals.positionCorrectCurrent + m.PositionComplianceSumMatch;
    totals.positionCorrectTotal =
      totals.positionCorrectTotal + m.PositionComplianceTotalSum;
  });

  return totals;
};

/** Normalise Aggregates to a PCT */
export function NormalisePct(
  pct: number,
  total: number,
  inverse: boolean = false
) {
  if (inverse) {
    return 100 - (pct / total) * 100;
  } else {
    return (pct / total) * 100;
  }
}

export interface AggregateEntity {
  Identifier: string;
  DisplayName: string;
  ImageUrl: string;
  StockCount: number;
  TotalCount: number;
  InStockPositions: number;
  TotalPositions: number;
  InStockShareOfShelfArea: number; // cm2
  ShareOfShelfTotalArea: number; //cm2
  LastCapturedDate: Date;
}

// Manufacturer Aggregate
export interface AggregateManufacturer {
  Manufacturer: string;
  StockCount: number;
  TotalCount: number;
  InStockPositions: number;
  TotalPositions: number;
  InStockShareOfShelfArea: number;
  ShareOfShelfTotalArea: number;
  LastCapturedDate: Date;
}

/**
 * Groups the product data of `allMetrics` or `myMetrics` by individual product.
 * @param metrics The successful API response data of `allMetrics` or `myMetrics`.
 * @returns
 */
export async function GroupProductData(metrics: StoreMetric[]) {
  let stockProductsMap: Map<string, AggregateEntity> = new Map();

  metrics.forEach((storeMetric) => {
    storeMetric.Products.forEach((product) => {
      const existingProduct = stockProductsMap.get(product.ProductId);

      if (existingProduct) {
        // If element already exists, update its counts.
        // BINARY INSTOCK
        existingProduct.StockCount += product.InStock ? 1 : 0;
        existingProduct.TotalCount += 1;

        // POSITION COMPLIANCE
        existingProduct.InStockPositions += product.InStockPositions;
        existingProduct.TotalPositions += product.TotalPositions;

        // INSTOCK AREA
        existingProduct.InStockShareOfShelfArea +=
          product.InStockShareOfShelfArea;
        existingProduct.ShareOfShelfTotalArea += product.ShareOfShelfTotalArea;

        // Update lastCaptured if the current product's date is more recent.
        if (
          product.LastCaptured &&
          product.LastCaptured > existingProduct.LastCapturedDate
        ) {
          existingProduct.LastCapturedDate = product.LastCaptured;
        }
      } else {
        // Otherwise, create a new one.
        stockProductsMap.set(product.ProductId, {
          Identifier: product.ProductId,
          DisplayName: product.ProductName,
          ImageUrl: `${process.env.REACT_APP_URSTORE_CONTENT_PREFIX}assets/products_thumbnail/${product.ImageUrl}`,

          // STOCK Y/N
          StockCount: product.InStock ? 1 : 0,
          TotalCount: 1,

          // POSITION STOCK
          InStockPositions: product.InStockPositions,
          TotalPositions: product.TotalPositions,

          // SHARE OF SHELF STOCK
          InStockShareOfShelfArea: product.InStockShareOfShelfArea,
          ShareOfShelfTotalArea: product.ShareOfShelfTotalArea,

          LastCapturedDate: product.LastCaptured,
        });
      }
    });
  });

  // Convert the map values to an array and sort it
  let stockProducts: AggregateEntity[] = Array.from(stockProductsMap.values());

  return stockProducts;
}

/**
 * Function to accurately coalesce disparate API metric aggregates into a single type for easier use.
 * "This is pretty redundant and will probably be deprecated in a later update." - Dec Keighley, 17 July 2024.
 */
export async function TransformToAggregateEntity(
  apiProducts: (OOSAggregate | OOPAggregate | SOSAggregate)[]
): Promise<AggregateEntity[]> {
  // Type guards to identify the specific aggregate type. These are scoped only to this containing function.
  function isOOSAggregate(p: any): p is OOSAggregate {
    return (p as OOSAggregate).StockCount !== undefined;
  }

  function isOOPAggregate(p: any): p is OOPAggregate {
    return (p as OOPAggregate).SumMatch !== undefined;
  }

  function isSOSAggregate(p: any): p is SOSAggregate {
    return (p as SOSAggregate).StockTotalArea !== undefined;
  }

  let mutated: AggregateEntity[] = [];

  apiProducts.forEach((p) => {
    // Sets up a shell with shared fields.
    let aggregateProduct: AggregateEntity = {
      Identifier: p.ProductId,
      // Use alternate display name if the entity is a manufacturer grouping.
      DisplayName: isSOSAggregate(p) ? p.Manufacturer : p.ProductName,
      ImageUrl: `${process.env.REACT_APP_URSTORE_CONTENT_PREFIX}assets/products_thumbnail/${p.ImageUrl}`,

      StockCount: isOOSAggregate(p) ? p.StockCount : 0,
      TotalCount: isOOSAggregate(p) ? p.TotalProduct : 0,

      InStockPositions: isOOPAggregate(p) ? p.SumMatch : 0,
      TotalPositions: isOOPAggregate(p) ? p.TotalSum : 0,

      InStockShareOfShelfArea: isSOSAggregate(p) ? p.StockTotalArea : 0,
      ShareOfShelfTotalArea: isSOSAggregate(p) ? p.TotalArea : 0,

      LastCapturedDate: p.LastCaptured ?? new Date(),
    };

    mutated.push(aggregateProduct);
  });

  return mutated;
}

/**
 * Groups the product data of `allMetrics` or `myMetrics` by Manufacturer.
 * @param metrics The successful API response data of `allMetrics` or `myMetrics`.
 * @returns
 */
export async function GroupProductDataByManufacturer(
  metrics: StoreMetric[]
) {
  let manufacturerProductsMap: Map<string, AggregateManufacturer> = new Map();

  metrics.forEach((storeMetric) => {
    storeMetric.Products.forEach((product) => {
      const existingManufacturer = manufacturerProductsMap.get(
        product.Manufacturer
      );

      if (existingManufacturer) {
        // If element already exists, update its counts.
        // BINARY INSTOCK
        existingManufacturer.StockCount += product.InStock ? 1 : 0;
        existingManufacturer.TotalCount += 1;

        // POSITION COMPLIANCE
        existingManufacturer.InStockPositions += product.InStockPositions;
        existingManufacturer.TotalPositions += product.TotalPositions;

        // INSTOCK AREA
        existingManufacturer.InStockShareOfShelfArea +=
          product.InStockShareOfShelfArea;
        existingManufacturer.ShareOfShelfTotalArea +=
          product.ShareOfShelfTotalArea;

        // Update lastCaptured if the current product's date is more recent.
        if (
          product.LastCaptured &&
          product.LastCaptured > existingManufacturer.LastCapturedDate
        ) {
          existingManufacturer.LastCapturedDate = product.LastCaptured;
        }
      } else {
        // Otherwise, create a new one.
        manufacturerProductsMap.set(product.Manufacturer, {
          Manufacturer: product.Manufacturer,
          StockCount: product.InStock ? 1 : 0,
          TotalCount: 1,

          // POSITION STOCK
          InStockPositions: product.InStockPositions,
          TotalPositions: product.TotalPositions,

          // SHARE OF SHELF STOCK
          InStockShareOfShelfArea: product.InStockShareOfShelfArea,
          ShareOfShelfTotalArea: product.ShareOfShelfTotalArea,

          LastCapturedDate: product.LastCaptured,
        });
      }
    });
  });

  // Convert the map values to an array and sort it
  let manufacturerProducts: AggregateManufacturer[] = Array.from(
    manufacturerProductsMap.values()
  );

  return manufacturerProducts;
}

// Util Sorts
export function SortByStockCount(
  aggregates: AggregateEntity[],
  asc: boolean = true
) {
  return aggregates.sort((a, b) => {
    const aPct = NormalisePct(b.StockCount, b.TotalCount, true);
    const bPct = NormalisePct(a.StockCount, a.TotalCount, true);
    if (aPct > bPct) {
      return asc ? 1 : -1;
    } else if (aPct < bPct) {
      return asc ? -1 : 1;
    } else {
      return 0;
    }
  });
}

export function SortByPositionCompliance(
  aggregates: AggregateEntity[],
  asc: boolean = true
) {
  return aggregates.sort((a, b) => {
    const aPct = NormalisePct(b.InStockPositions, b.TotalPositions, true);
    const bPct = NormalisePct(a.InStockPositions, a.TotalPositions, true);
    if (aPct > bPct) {
      return asc ? 1 : -1;
    } else if (aPct < bPct) {
      return asc ? -1 : 1;
    } else {
      return 0;
    }
  });
}

export function SortByManufacturerShareOfShelf(
  aggregates: AggregateManufacturer[],
  asc: boolean = true
) {
  return aggregates.sort((a, b) => {
    const aPct = NormalisePct(
      b.InStockShareOfShelfArea,
      b.ShareOfShelfTotalArea,
      true
    );
    const bPct = NormalisePct(
      a.InStockShareOfShelfArea,
      a.ShareOfShelfTotalArea,
      true
    );
    if (aPct > bPct) {
      return asc ? 1 : -1;
    } else if (aPct < bPct) {
      return asc ? -1 : 1;
    } else {
      return 0;
    }
  });
}

export const LoadingBox = ({ height }: { height?: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: height ?? "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};